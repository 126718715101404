@charset 'UTF-8';
@import './setting/variables';

// common
.GPC0060 {
	max-width:(1284px+60px);
	padding-left:30px;
	padding-right:30px;
	@include screen(custom, max, $max-sm) {
		padding-left:0;
		padding-right:0;
	}
	// 1200px ~
	@include screen(xl){
		padding-top:(24px * 100%) / 1284px;
	}
	// 768px ~ 1199px
	@include screen(custom, min, $min-md){
		@include screen(custom, max, $max-lg){
			padding-top:(24px * 100%) / 992px;
		}
	}
	.square {
		display:inline-block;
		vertical-align:top;
		// 320px ~ 767px
		@include screen(custom, max, $max-sm){
			width:100%;
		}
		.common-area {
			position:relative;
			display:block;
			width:100%;
			height:100%;
			&.case-of-video:before {
				content:"";
				display:block;
				width:32px;
				height:32px;
				background : {
					/* LGEGMC-432 20200812 modify */
					image: url('/lg5-common-gp/images/components/btn-play.svg');
					/* //LGEGMC-432 20200812 modify */
					repeat:no-repeat;
					position:50% 50%;
					size:100% 100%;
					color:rgba(0,0,0,.25);
				}
				border-radius:50% 50%;
				position: absolute;
				z-index:1;
				bottom:20px; left:20px;
				[dir="rtl"] & {
					left:auto;
					right:20px;
				}
				// 320px ~ 767px
				@include screen(custom, max, $max-sm){
					bottom:25px; left:24px;
					[dir="rtl"] & {right:24px;}
				}
			}
		}
		.visual-area {
			position: absolute;
			width:100%;
			height:100%;
			img {
				display:none;
				width:100%;
				height:100%;	// wrong image size check for admin
			}
			// 1200px ~
			@include screen(xl){
				img.pc-lg {display:block}
			}
			// 768px ~ 1199px
			@include screen(custom, min, $min-md){
				@include screen(custom, max, $max-lg){
					img.pc-md {display:block}
				}
			}
			// 320px ~ 767px
			@include screen(custom, max, $max-sm){
				img.mobile {display:block}
			}
		}
		.copy-boxing-area {
			position: absolute;
			z-index:1;
			top:25px; left:20px; right:20px; bottom:20px;
			margin:auto;
			background-color:transparent;
			font-size:16px;
			line-height:24px;
			.category,
			.head{
				@include font-family($font-semibold);
				@include screen(custom, max, $max-sm){
					max-width:210px;
				}
			}
			.category {
				max-width:220px;
			}
			.head {
				max-width:250px;
				h2, h3, h4, h5, h6 {
					font-size:inherit;
					line-height:inherit;
					// 20200429 START 박지영 - font-weight 추가
					font-weight:inherit;
					// 20200429 END
				}
				@include screen(custom, max, $max-sm){
					max-width:210px;
				}
			}
		}
		&.text-white {
			// background-color: $bg-gray;
			color:$color-white;
			.copy-boxing-area {
				color:$color-white;
			}
		}
		&.text-black {
			// background-color: $bg-white;
			color:$color-black;
			.copy-boxing-area {
				color:$color-white;
				.category {color:$color-nightrider;}
				.head {color:$color-black;}
			}
		}
	}
}

// 2 squares
.GPC0060 {
	// mixin
	@mixin square($calc-type, $part-size, $container-size, $rtlUse:null){
		@if($calc-type == "w"){
			width:($part-size * 100%) / $container-size;
		};
		@if($calc-type == "mr"){
			margin-right:($part-size * 100%) / $container-size;
			@if($rtlUse == "true"){
				[dir="rtl"] & {
					margin-right:0;
					margin-left:($part-size * 100%) / $container-size;
				}
			};
		};
	}
	// element
	.component-inner {
		font-size:0;
	}
	.square {
		// .common-area {
		// 	&.case-of-video:before {
		// 		@include screen(custom, max, $max-sm){
		// 			bottom:25px; left:24px;
		// 		}
		// 	}
		// }
		&:first-child{
			// 1200px ~
			@include screen(xl){
				@include square(mr, 24px, 1284px, "true");
			}
			// 768px ~ 1199px
			@include screen(custom, min, $min-md){
				@include screen(custom, max, $max-lg){
					@include square(mr, 24px, 992px, "true");
				}
			}
			// 320px ~ 767px
			@include screen(custom, max, $max-sm){
				@include square(mr, 0px, 992px, "true");
			}
		}
		&.main {
			// 1200px ~
			@include screen(xl){
				@include square(w, 848px, 1284px);
			}
			// 768px ~ 1199px
			@include screen(custom, min, $min-md){
				@include screen(custom, max, $max-lg){
					@include square(w, 593px, 992px);
				}
			}
		}
		.head{
			font-size:28px;
			line-height:32px;
			margin-top:3px;
			@include screen(custom, max, $max-md){
				font-size:20px;
				line-height:26px;
				margin-top:5px;
			}
		}
		&.sub {
			// 1200px ~
			@include screen(xl){
				@include square(w, 412px, 1284px);
			}
			// 768px ~ 1199px
			@include screen(custom, min, $min-md){
				@include screen(custom, max, $max-lg){
					@include square(w, 375px, 992px);
				}
			}
		}
		.copy-boxing-area {
			top:23px;
			right:20px;
			bottom:20px;
			left:20px;
			[dir="rtl"] & {
				text-align: right;
			}
			// 320px ~ 767px
			@include screen(custom, max, $max-sm){
				top:18px; right:24px; bottom:25px; left:24px;
			}
		}
		// 320px ~ 767px
		@include screen(custom, max, $max-sm){
			margin-top:10px;
		}
	}
	// image size
	.square {
		// 1200px ~
		@include screen(xl){
			&.main {
				.common-area {padding-bottom:42.807%;}
			}
			&.sub {
				.common-area {padding-bottom:88.11%;}
			}
		}
		// 768px ~ 1199px
		@include screen(custom, min, $min-md){
			@include screen(custom, max, $max-lg){
				&.main {
					.common-area {padding-bottom:61.215%;}
				}
				&.sub {
					.common-area {padding-bottom:96.8%;}
				}
			}
		}
		// 320px ~ 767px
		@include screen(custom, max, $max-sm){
			&.main,
			&.sub {
				.common-area {padding-bottom:66.563%;}
			}
		}
	}
}

@media screen and (-ms-high-contrast: active ) {
	.GPC0060 .visual-area {
		img {
			visibility: hidden;
		}
	}
}
